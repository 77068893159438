<template>
  <h1 style="color: #FFFFFF;" >CONOCE A NUESTROS EMPRENDEDORES</h1>
  <div uk-grid class="uk-child-width-1-5@s">
      <div v-for="item in sorted" :key="item[0]">
<router-link  :to="'/emprendedor/'+item[0]" class="uk-card uk-card-body uk-card-default uk-padding-remove uk-flex" :uk-tooltip="'title:'+ item[1].name" style="height: 204.19px; border-radius:25px">
                  <img :src="item[1].logo" 
                  style="width: 100%; object-fit: contain; max-height: 204.19px; border-radius:25px" alt="">
</router-link>
      </div>
  </div>
      




</template>

<script>
import { ref } from 'vue';
import { getDatabase, ref as dbref, get } from "firebase/database";
export default {
  setup(){
    const emprendedores = ref({})
    const sorted = ref([])
    get(dbref(getDatabase(),'emprendedores/emprendedores')).then((s)=>{
      if(s.exists()){
        emprendedores.value = s.toJSON()
        sorted.value = Object.entries(emprendedores.value).sort((a,b)=>{
          if(a[1].name > b[1].name) return 1
          if(a[1].name == b[1].name) return 0
          if(a[1].name < b[1].name) return -1
        })
      }
    })
    return {
      emprendedores,
      sorted
    }
  }
}
</script>

<style>

</style>